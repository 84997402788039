module.exports = [{
      plugin: require('/codebuild/output/src657994251/src/node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":760,"wrapperStyle":{},"linkImagesToOriginal":false},
    },{
      plugin: require('/codebuild/output/src657994251/src/node_modules/gatsby-plugin-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://brunnertechnicalservices.com"},
    },{
      plugin: require('/codebuild/output/src657994251/src/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
